<template>
  <div v-if="isAuthLoggedIn()">
    <slot />
  </div>
</template>

<script setup lang="ts">
const { isAuthLoggedIn } = useMenuModel()

const localePath = useLocalePath()
const router = useRouter()
const route = useRoute()

if (!isAuthLoggedIn()) {
  router.replace(
    localePath({ path: '/auth/login', query: { callbackUrl: route.path } })
  )
}
</script>
